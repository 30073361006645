import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./home/Home";
import Score from "./score/Score";
import ScoreBug from "./score/ScoreBug";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/score" element={<Score />} />
          <Route exact path="/scorebug" element={<ScoreBug />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
