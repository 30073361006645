import firebase from "firebase";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB_oQFe6_yYdOcqj95XT0Zt2Ay94S3DN9U",
  authDomain: "volley-stats.firebaseapp.com",
  databaseURL: "https://volley-stats.firebaseio.com",
  projectId: "volley-stats",
  storageBucket: "volley-stats.appspot.com",
  messagingSenderId: "746174909898",
  appId: "1:746174909898:web:d5e3c4626372677bf81333",
  measurementId: "G-SLR7YKSSG1",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const db = app.firestore();

export {
  db,
};
