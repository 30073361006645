import "../App.css";
import "./score.css";

import React, { useEffect, useState } from "react";

import bug from "../bug.png";
import { db } from "../firebase";
import { useLocation } from "react-router-dom";

const SelectTeam = ({ team, setTeam }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [searching, setSearching] = useState(false);
  const [joinCode, setJoinCode] = useState("");
  const searchTeams = () => {
    setSearching(true);
    db.collection("teams")
      .where("joinCode", "==", joinCode)
      .get()
      .then((querySnapshot) => {
        let teamArr = [];
        let doc = querySnapshot.docs.forEach((doc) => {
          let tmpteam = doc.data();
          tmpteam.id = doc.id;
          let split = tmpteam.name.trim().split(" ");
          if (split.length > 1) {
            tmpteam.name = split.slice(0, -1).join(" ");
          }
          teamArr.push(tmpteam);
        });
        if (teamArr.length > 0) {
          setSearching(false);
          setTeam(teamArr[0]);
        } else {
          setSearching(false);
          setErrorMessage("No Team Found");
        }
      })
      .catch((error) => {
        setSearching(false);
        setErrorMessage(error);
      });
  };

  return (
    <div>
      <h1>Search for a team</h1>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          margin: "4px 0",
        }}
      >
        <input
          value={joinCode}
          onChange={(e) => setJoinCode(e.target.value)}
          placeholder="Join Code"
        />
        <button onClick={() => searchTeams()}>
          {searching ? "Searching" : "Search for Team"}
        </button>
      </div>
      {errorMessage ? errorMessage : null}
    </div>
  );
};

const SelectGame = ({ team, setGame }) => {
  console.log(team.games);
  return (
    <div>
      <h1>Select Game</h1>
      <h3>{team.name}</h3>
      {team.games ? (
        <table>
          <tbody>
            {team.games.map((game, index) => {
              return (
                <tr>
                  <td>{game.date.toDate().toLocaleDateString()}</td>
                  <td>{game.teamName}</td>
                  <td>
                    <button onClick={() => setGame(game)}>Select Game</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        "Loading Games..."
      )}
    </div>
  );
};

const Bug = ({ team, game }) => {
  const [scores, setScores] = useState([
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
  ]);
  const [currentSet, setCurrentSet] = useState(1);

  useEffect(() => {
    for (let i = 0; i < 5; i++) {
      db.doc(
        "teams/" + team.id + "/games/" + game.id + "/sets/" + (i + 1)
      ).onSnapshot(async (doc) => {
        const data = doc.data();
        let tmpScores = [...scores];
        tmpScores[i][0] = data["ourScore"];
        tmpScores[i][1] = data["theirScore"];
        if (data["ourScore"] > 0 || data["theirScore"] > 0) {
          if (
            (data["ourScore"] >= 25 && data.ourScore - data.theirScore >= 2) ||
            (data["theirScore"] >= 25 && data.theirScore - data.ourScore >= 2)
          ) {
            setScores(tmpScores);
            await new Promise((r) => setTimeout(r, 10000));
            if (i != 4) {
              setCurrentSet(i + 1);
            } else {
              setCurrentSet(i);
            }
          } else {
            setScores(tmpScores);
            setCurrentSet(i);
          }
        }
      });
    }
  }, []);

  const getSetScore = (who) => {
    let setScore = [0, 0];
    for (let i = 0; i < currentSet; i++) {
      if (scores[i][0] > scores[i][1]) {
        setScore[0] += 1;
      }
      if (scores[i][1] > scores[i][0]) {
        setScore[1] += 1;
      }
    }
    if (currentSet == 4) {
      if (
        scores[currentSet][0] >= 15 &&
        scores[currentSet][0] - scores[currentSet][1] >= 2
      ) {
        setScore[0] += 1;
      }
      if (
        scores[currentSet][1] >= 15 &&
        scores[currentSet][1] - scores[currentSet][0] >= 2
      ) {
        setScore[1] += 1;
      }
    }
    return setScore[who];
  };

  return (
    <div className="score">
      <img src={bug} />
      <div className="nameOne">{team.name}</div>
      <div className="nameTwo">{game.teamName}</div>
      <div className="setOne">{getSetScore(0)}</div>
      <div className="setTwo">{getSetScore(1)}</div>
      <div className="scoreOne blue">{scores[currentSet][0]}</div>
      <div className="scoreTwo blue">{scores[currentSet][1]}</div>
    </div>
  );
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ScoreBug() {
  let query = useQuery();

  const [team, setTeam] = useState(null);
  const [game, setGame] = useState(null);

  useEffect(() => {
    db.doc("teams/" + query.get("tid"))
      .get()
      .then((doc) => {
        let tmpteam = doc.data();
        tmpteam.id = doc.id;
        let split = tmpteam.name.trim().split(" ");
        if (split.length > 1) {
          tmpteam.name = split.slice(0, -1).join(" ");
        }
        setTeam(tmpteam);
      })
      .catch((err) => {
        console.log(err);
      });
    db.doc("teams/" + query.get("tid") + "/games/" + query.get("gid"))
      .get()
      .then((doc) => {
        let tmpGame = doc.data();
        tmpGame.id = doc.id;
        let split = tmpGame.teamName.trim().split(" ");
        if (split.length > 1) {
          tmpGame.teamName = split.slice(0, -1).join(" ");
        }
        setGame(tmpGame);
      });
  }, []);
  return <div>{game && team ? <Bug team={team} game={game} /> : null}</div>;
}

export default ScoreBug;
